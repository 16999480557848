
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('li', { 'className': 'grid-item' }, _createElement('product-card', { 'class': 'card card-product card-product-no-rating card-product-animate-footer color-background-2' }, _createElement('a', { 'href': this.url }, _createElement('img', {
                    'className': 'cm_product-item__image' + (this.image2 ? ' cm_product-item__image_primary' : ''),
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }), this.image2 ? _createElement('img', {
                    'className': 'cm_product-item__image cm_product-item__image_secondary',
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '410'
                }) : null), _createElement('div', { 'className': 'card-info-wrap' }, _createElement('div', { 'className': 'card-info' }, _createElement('div', { 'className': 'card-eyebrow' }, _createElement('a', Object.assign({}, { 'href': this.vendor_url }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('h3', { 'className': 'card-heading typography-headings' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'js-product-link'
                }, { dangerouslySetInnerHTML: { __html: this.title } }))), this.stock?.includes('In-stock Only') ? _createElement('div', {
                    'className': 'badge-stock badge-stock-in',
                    'key': '1053'
                }, _createElement('span', { 'className': 'badge-stock-icon' }), ' In stock\n        ') : null), _createElement('div', { 'className': 'card-footer product-rating-hide-empty' }, _createElement('div', { 'className': 'price' }, this.on_sale ? _createElement('span', {
                    'className': 'badge badge-sale',
                    'key': '1326'
                }, ' SALE ') : null, _createElement('div', { 'className': 'price-container' }, this.on_sale ? _createElement('div', {
                    'className': 'price-sale',
                    'key': '1444'
                }, _createElement('span', { 'className': 'price-item price-item-sale price-item-last' }, '\n                ', this.formatPrice(this.compare_at_price), '\n              ')) : null, this.price_varies ? _createElement('div', {
                    'className': 'unit-price',
                    'key': '1673'
                }, _createElement('div', {}, _createElement('span', { 'className': 'sup' }, 'From'), this.formatPrice(this.price))) : null, _createElement('div', { 'className': 'price-regular' }, this.price ? _createElement('span', {
                    'className': 'price-item price-item-regular',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '1883'
                }, '\n                ', this.formatPrice(this.price), '\n              ') : null))), _createElement('div', { 'className': 'card-floating-footer' }, _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'cm_product-item__buttons-form product-actions card-product-actions',
                    'id': this.id,
                    'encType': 'multipart/form-data'
                }, this.variant_ids.length > 1 && !this.out_of_stock ? [_createElement('a', {
                        'className': 'cm_button cm_button__secondary button button-outline',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '23751'
                    }, '\n                Select options\n              ')] : null, this.variant_ids.length === 1 && !this.out_of_stock ? [
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids,
                        'key': '26621'
                    }),
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': '1',
                        'key': '26623'
                    }),
                    _createElement('button', {
                        'className': 'cm_button button button-secondary button-solid js-product-link',
                        'type': 'submit',
                        'data-form-id': this.id,
                        'key': '26625'
                    }, '\n                Add to cart\n              ')
                ] : null, this.out_of_stock ? [_createElement('button', {
                        'className': 'cm_button button button-secondary button-solid js-product-link',
                        'type': 'submit',
                        'data-form-id': this.id,
                        'disabled': 'true',
                        'key': '31031'
                    }, '\n                Sold out\n              ')] : null))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []