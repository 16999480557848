
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function garageRT () {
    return _createElement('div', { 'className': 'cm_garage' }, _createElement('a', {
        'className': 'cm_toggle-dropdown garage-button',
        'title': 'Garage: ' + this.size + ' item(s)'
    }, _createElement('div', { 'className': 'garage-icon-container' }, _createElement('svg', {
        'className': 'cm_icon cm_icon-garage',
        'xmlns': 'http://www.w3.org/2000/svg',
        'viewBox': '0 0 302 302'
    }, _createElement('path', {
        'fill': '#fff',
        'd': 'M295 59.886H7a7 7 0 00-7 7v168.229a7 7 0 007 7h26a7 7 0 007-7v-35.229h34.333v7c0 9.246 6.422 17.001 15.034 19.093.722 3.097 3.494 5.407 6.811 5.407h14.5a7 7 0 006.654-4.833h67.336a7 7 0 006.654 4.833h14.5c3.317 0 6.089-2.31 6.811-5.407 8.612-2.091 15.034-9.847 15.034-19.093v-7H262v35.229a7 7 0 007 7h26a7 7 0 007-7V66.886a7 7 0 00-7-7zm-81.333 147a5.673 5.673 0 01-5.667 5.667H94a5.673 5.673 0 01-5.667-5.667v-7h10.124c.595 3.132 3.343 5.501 6.648 5.501s6.053-2.369 6.649-5.501h78.492c.596 3.132 3.344 5.501 6.649 5.501s6.053-2.369 6.648-5.501h10.124v7zm7-21H40v-5.333h222v5.333h-41.333zm41.333-44v5.333H40v-5.333h222zm0 19.333v5.333H40v-5.333h222zm0-38.666v5.333H40v-5.333h222zm0-14H40v-5.667h222v5.667zm26 119.561h-12V95.886a7 7 0 00-7-7H33a7 7 0 00-7 7v132.229H14V73.886h274v154.228z'
    })), this.size ? [_createElement('span', {
            'className': 'garage-size head-slot-cart-link-quantity',
            'key': 'garage-size'
        }, this.size)] : null)), [this.garageDropdown(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        return _createElement('div', { 'className': 'garage-item' }, _createElement('span', {
                            'className': 'select-model',
                            'data-cm-role': 'select-vehicle'
                        }, this.vehicleString), _createElement('span', {
                            'className': 'remove-model',
                            'data-cm-role': 'remove-vehicle'
                        }, [_createElement('svg', {
                                'className': 'cm_icon cm_icon-times',
                                'height': '20px',
                                'role': 'img',
                                'viewBox': '0 0 22 22',
                                'key': '1910'
                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_garageDropdown' }, this.template === 'list' ? _createElement('div', {
                'className': 'cmTemplate_list',
                'key': '31'
            }, _createElement('div', { 'className': 'garage-content' }, _createElement('div', {
                'className': 'clear-garage',
                'data-cm-role': 'clear-garage'
            }, 'Clear garage'), _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.addButton(function () {
                    return _createElement('div', { 'className': 'add-vehicle  button button-secondary cm_addButton' }, [window.Convermax.config?.garageButtonText || 'Add a vehicle']);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })])) : null, this.template === 'empty' ? _createElement('div', {
                'className': 'cmTemplate_empty',
                'key': '1430'
            }, _createElement('div', { 'className': 'garage-content' }, [this.addButton(function () {
                    return _createElement('div', { 'className': 'add-vehicle  button button-secondary cm_addButton' }, [window.Convermax.config?.garageButtonText || 'Add a vehicle']);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('ul', {}, _createElement('li', { 'className': 'vehicle-advantages' }, 'Store your vehicle in the garage'), _createElement('li', { 'className': 'vehicle-advantages' }, 'Get products for your vehicle'), _createElement('li', { 'className': 'vehicle-advantages' }, 'Easily find the parts you need')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', { 'className': 'garage-shadow-layout' }));
}
        export const componentNames = ["cm:addButton","cm:addButton","cm:garageDropdown"]