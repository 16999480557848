//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-88:_5536,_8462,_6592,_8856,_3016,_8496,_7820,_7736;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-88')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-88', "_5536,_8462,_6592,_8856,_3016,_8496,_7820,_7736");
        }
      }catch (ex) {
        console.error(ex);
      }